<template>
  <div class="importCSV">
    <HeadTitle :mainTitle="title"/>
    <div class="btn-group" role="group" aria-label="Basic radio toggle button group" style="margin-bottom:40px">
      <input type="radio" class="btn-check" name="btnradio" id="radioAdd" autocomplete="off" checked @click="clickChangeAction">
      <label class="btn btn-outline-primary" for="radioAdd">{{ $t('upload.add') }}</label>

      <input type="radio" class="btn-check" name="btnradio" id="radioEdit" autocomplete="off" @click="clickChangeAction">
      <label class="btn btn-outline-primary" for="radioEdit">{{ $t('upload.edit') }}</label>

      <input type="radio" class="btn-check" name="btnradio" id="radioDeparture" autocomplete="off" @click="clickChangeAction">
      <label class="btn btn-outline-primary" for="radioDeparture">{{ $t('upload.departure') }}</label>

      <input type="radio" class="btn-check" name="btnradio" id="radioReactivate" autocomplete="off" @click="clickChangeAction">
      <label class="btn btn-outline-primary" for="radioReactivate">{{ $t('upload.reactivate') }}</label>
    </div>
    <div class="form">
      <div class="input-group mb-3 custom-file-button">
        <label class="input-group-text" for="inputGroupFile02">{{ $t("upload.selectFile") }}</label>
        <input ref="fileUploader" type="file" class="form-control" id="inputGroupFile02" accept=".csv" @change="onFileChange"  @click="resetFileUploader">
      </div>
      <br>
      <div class="d-grid gap-2 col-4 mx-auto">
        <button class="btn btn-primary" type="button" @click="uploadCSV()" data-bs-toggle="modal" data-bs-target="#staticBackdrop">{{ $t('upload.confirm') }} {{ actionSelected }}</button>
      </div>
    </div>
    <br>
    <div style="margin-top: 50px; text-align:left">
      <span>{{ $t("upload.templateCSV") }}</span>
      <button class="btn btn-primary" @click="downloadTemplate()">{{ $t('modal.download') }}</button>
    </div>
    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">{{ $t('modal.response') }}</h5>
            </div>
            <div class="modal-body">
              <span v-if="isFailed && !loading" style="color:red">{{ message }}</span>
              <span v-else>{{ message }}</span>
              <br>
              <button class="btn btn-primary" v-show="needFile" @click="downloadError()">{{ $t('modal.download') }}</button>
              <div class="d-flex justify-content-center">
                <div class="spinner-border text-primary" v-show="loading" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button ref="closeModal" type="button" class="btn btn-secondary" v-bind:class="{disabled: loading}" data-bs-dismiss="modal">{{ $t('modal.close') }}</button>
            </div>
          </div>
        </div>
      </div>
      <button style="display:none" ref="callModal" type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop"></button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import { getName } from 'country-list'
import HeadTitle from '../../components/HeadTitle.vue'
export default {
  name: 'UploadCSV',
  components: {
    HeadTitle
  },
  data() {
    return {
      //Content of the uploaded CSV
      csvContent : {},
      //State of the API calls, used by modal
      loading: false,
      //Message to print in the modal
      message: "",
      //Used to put text in red in modal
      isFailed: false,
      //If the modal requires to send a file with the list of errors
      needFile: false,
      //Content of the file to return to the user in case of error
      fileContent: "",
      //Action selected (create,update,deactivation)
      actionSelected: this.$t('upload.creation'),
      //Title of the page
      title: this.$t('upload.titleAdd')
    }
  },
  computed:{
    ...mapState(['claims', 'role', 'groupName', 'groupId']),
  },
  methods: {

    /**
     * Reset the input when upload another file
     */
    resetFileUploader(){
      this.$refs.fileUploader.value = '';
    },
    /**
     * Download the file containing the list of errors
     */
    downloadError(){
      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.fileContent));
      element.setAttribute('download', "error_list.txt");

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },

    /**
     * Download the file containing the CSV template
     */
    downloadTemplate(){
      var element = document.createElement('a');
      const templateAddAdmin = "firstName;lastName;username;startDate;terminationDate;countrycode;externalCompany;language\ntest;example;test.example@test.com;12/31/2022;08/31/2023;FR;testCompany;FR"
      const templateAddManager = "firstName;lastName;username;startDate;terminationDate;countrycode;language\ntest;example;test.example@test.com;12/31/2022;08/31/2023;FR;FR"
      const templateUpdate = "firstName;lastName;username;terminationDate;language\ntest;example;test.example@test.com;10/20/2023;FR"
      const templateDeparture = "username\ntest.example@test.com"
      const templateReactivate = "username;terminationDate\ntest.example@test.com;08/31/2023"
      let templateContent = document.getElementById("radioAdd").checked && this.role !="Manager" ? templateAddAdmin : document.getElementById("radioAdd").checked && this.role == "Manager" ? templateAddManager : document.getElementById("radioEdit").checked ? templateUpdate : document.getElementById("radioDeparture").checked ? templateDeparture : document.getElementById("radioReactivate").checked ? templateReactivate : ""
      let fileName =document.getElementById("radioAdd").checked ? "template_create.csv" : document.getElementById("radioEdit").checked ? "template_update.csv" : document.getElementById("radioDeparture").checked ? "template_departure.csv" : document.getElementById("radioReactivate").checked ? "template_reactivate.csv" : ""
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(templateContent));
      element.setAttribute('download', fileName);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },

    /**
     * Change the action selected
     */
    clickChangeAction(){
      document.getElementById("inputGroupFile02").value = "";
      this.actionSelected = document.getElementById("radioAdd").checked ? this.$t('upload.creation') : document.getElementById("radioEdit").checked ? this.$t('upload.update') : document.getElementById("radioDeparture").checked ? this.$t('upload.departure') : document.getElementById("radioReactivate").checked ? this.$t('upload.reactivate') :""
      this.title = document.getElementById("radioAdd").checked ? this.$t('upload.titleAdd') : document.getElementById("radioEdit").checked ? this.$t('upload.titleUpdate') : document.getElementById("radioDeparture").checked ? this.$t('upload.titleDeparture') : document.getElementById("radioReactivate").checked ? this.$t('upload.titleReactivate') : ""
    },

    //Return if a blob (file content) is in UTF-8 or not
    async isUTF8(blob) {
      const decoder = new TextDecoder('utf-8', { fatal: true });
      const buffer = await blob.arrayBuffer();
      try {
        decoder.decode(buffer);
      } catch (e) {
        if (e instanceof TypeError)
          return false;
        throw e;
      }
      return true;
    },

    /**
     * Action triggered when a file is upload in the input form element
     */
    async onFileChange(e){
      //Refreshing related states
      this.fileContent = ""
      this.message = this.$t('modal.waiting_msg')
      this.needFile = false
      this.csvContent = {}
      const file = e.target.files[0];
      //Read the file
      const reader = new FileReader();
      if(await this.isUTF8(file)){
         reader.readAsText(file)
      } else {
        //if not UTF-8, read as windows-1252 which is excel encoded format in french
        reader.readAsText(file, 'windows-1252')
      }
      reader.onload = e => {
        const input = e.target.result
        //If the content contains one � (unreadable caracter due to incorrect encoding) => return an error 
        if(input.includes('�')){
          document.getElementById("inputGroupFile02").value = "";
          this.message = this.$t('modal.error_encoding')
          this.isFailed = true
          this.needFile = false
          this.$refs.callModal.click()
          return
        }
        //Put the content of the csv in a json object
        const lines = input.split('\n')
        for(let line in lines){
          lines[line] = lines[line].toString().replace(/\r?\n|\r/g, '')
        }
        if(!lines[lines.length-1]) lines.pop()
        const header = lines[0].split(';')
        for(let head in header){
          header[head] = header[head].toLowerCase()
        }
        this.csvContent = lines.slice(1).map(line => {
          const fields = line.split(';')
          return Object.fromEntries(header.map((h, i) => [h, fields[i]]))
        })

        //Check the action and validate the CSV
        //csv_validation needs to be an array if not true with an element being an error message
        let action = document.getElementById("radioAdd").checked ? "A" : document.getElementById("radioEdit").checked ? "U" : document.getElementById("radioDeparture").checked ? "D" : document.getElementById("radioReactivate").checked ? "R" : alert("Please select an action")
        let csv_validation = this.isCSV_Valid(this.csvContent, this.claims.email, this.role, action)
        if(csv_validation != true){
          //If CSV not valid return list of errors
          this.$refs.callModal.click()
          document.getElementById("inputGroupFile02").value = "";
          for(let validation in csv_validation){
            this.fileContent += csv_validation[validation]
            this.message = this.$t('modal.error_file_msg')
            this.isFailed = true
            this.needFile = true
          }
        }
        else{
          //If CSV valid auto-close modal
          this.message = this.$t('modal.csv_valid')
          this.$refs.closeModal.click()
        }
      };
    },

    /**
     * Parent Function which call child function which trigger all the API Calls 
     */
    async uploadCSV(){
      //Refresh related state
      this.needFile = false
      this.loading = true
      this.isFailed = false
      this.fileContent = ""
      this.message = this.$t('modal.waiting_msg')
      //If the file is empty, ask for a csv
      if(Object.keys(this.csvContent).length == 0){
        this.message = this.$t('modal.ask_csv')
        this.isFailed = true
        this.loading = false
        return
      }

      //If the action is create trigger the creations (based also on role) for retrieving groups
      if(document.getElementById("radioAdd").checked){
        if(this.role == "Manager"){
          await this.addUsersAsManager()
        } else {
          await this.addUsersAsAdmin()
        }
        //Set success state
        if(!this.isFailed) this.message = this.$t('modal.users_created')
        else {
          //Set Failure state
          this.needFile = true
          this.message = this.$t('modal.users_created_passed_with_errors')
        }
      }

      //If the action is update trigger the users update
      else if(document.getElementById("radioEdit").checked){
        await this.updateUsers()
        //Set success state
        if(!this.isFailed) this.message = this.$t('modal.users_updated')
        else {
          //Set Failure state
          this.needFile = true
          this.message = this.$t('modal.users_updated_passed_with_errors')
        }
      }

      //If the action is departure or reactivation, trigger the deactivation or reaction
      else if(document.getElementById("radioDeparture").checked || document.getElementById("radioReactivate").checked){
        const action = document.getElementById("radioDeparture").checked ? "deactivate" : document.getElementById("radioReactivate").checked ? "activate" : ""
        await this.deactivateOrReactivateUsers(action)
        //Set success state
        if(!this.isFailed){
          this.message = document.getElementById("radioDeparture").checked ? this.$t('modal.users_deactivated') : document.getElementById("radioReactivate").checked ? this.$t('modal.users_reactivated') : ""
        }
        else {
          //Set Failure state
          this.needFile = true
          this.message = document.getElementById("radioDeparture").checked ? this.$t('modal.users_deactivated_passed_with_errors') : document.getElementById("radioReactivate").checked ? this.$t('modal.users_reactivated_passed_with_errors') : ""
        }
      }
      //Supposedly impossible condition => Ask to select Action
      else {
        this.message = this.$t('modal.select_action')
      }
      this.loading = false
    },

    /**
     * Function to trigger in order to add users if the user is a manager
     */
    async addUsersAsManager(){
      //API calls to get the referent
      //first get the group description
      let group
      let externalCompany = this.groupName.normalize("NFD").replace(/\p{Diacritic}/gu, "").toUpperCase()

      const url = encodeURI(process.env.VUE_APP_BACKEND_URI + "api/v1/groups?search=profile.name+eq+\"USERS_" + externalCompany + "\"")
      const headers = {
        "Accept": "application/json",
        "Content-Type": "application/json"
      }
      await axios.get(url,{headers}).then(res => {
        group = {
          id: res.data[0].id,
          name: res.data[0].profile.name,
          description: res.data[0].profile.description
        }
      })
      
      //For each users in the CSV, 
      for(let user in this.csvContent){
        if(this.isFailed) break
        //API call for user creation
        const url = encodeURI(process.env.VUE_APP_BACKEND_URI + "api/v1/users?activate=true")
        const headers = {
            "Accept": "application/json",
            "Content-Type": "application/json"
          }
        //add calculated data
        let data = {
          "profile": {
            "login": this.csvContent[user].username,
            "firstName": this.csvContent[user].firstname,
            "lastName": this.csvContent[user].lastname,
            "email": this.csvContent[user].username,
            "displayName": this.csvContent[user].firstname + " " + this.csvContent[user].lastname,
            "streetAddress": "OUT OF MH",
            "countryCode": this.csvContent[user].countrycode,
            "locale": this.languageToLocale(this.csvContent[user].language),
            "department": "OUT OF MH",
            "managerId": group.description,
            "initials": "#PRT",
            "externalCompany": externalCompany,
            "startDate": this.csvContent[user].startdate,
            "terminationDate": this.csvContent[user].terminationdate,
            "division": "OUT OF MH",
          }, //groups to manage
          "groupIds": [
            this.groupId
          ]
        }
        try {
          await axios.post(url, data ,{headers})
        } catch (e) {
          console.error(e)
          //Set error state & add errors to the files
          this.isFailed = true
          this.fileContent += "Target : " + this.csvContent[user].username + "\nError : "
          if(e.response.data.errorCauses.length == 0){
            this.fileContent += e.response.data.errorSummary + "\n"
          }
          else if(e.response.data.errorCauses === undefined){
            this.fileContent += this.$t('error.unknown') + "\n"
          } else {
            for(let i in e.response.data.errorCauses){
              this.fileContent += e.response.data.errorCauses[i].errorSummary + "\n"
            }
          }
          continue
        }
        
      }
    },


    //function to trigger in order to add users if the user is a MH Referent, B2B MH Portal Admin or a Service Desk user
    async addUsersAsAdmin(){
      
      //array declaration with all known groups with id and name
      let knownGroups = []
      //iteration for known groups
      let numGroups = 0
      //For each user in the CSV
      for(let user in this.csvContent){
        let increment = false
        let group
        //name of external company for x line of the csv
        let externalCompany = this.csvContent[user].externalcompany.normalize("NFD").replace(/\p{Diacritic}/gu, "").toUpperCase()
        //if unknown check if known in variable
        if(typeof group === "undefined"){
          group = Object.values(knownGroups).find((obj) => {
            return obj.name == "USERS_" + externalCompany
          })
        } else {
          //if not, increment numGroups and set group in knowngroups
          increment = true
          knownGroups[numGroups] = group
        }
        //if unknown group => get group from API call
        if(typeof group === "undefined"){
          const url = encodeURI(process.env.VUE_APP_BACKEND_URI + "api/v1/groups?search=profile.name+eq+\"USERS_" + externalCompany + "\"")
          const headers = {
            "Accept": "application/json",
            "Content-Type": "application/json"
          }
          //Retrieve users group of the given external company
          try{
            await axios.get(url,{headers}).then(res => {
              //If not find => throw -1
              if(res.data.length == 0) {
                throw -1
              } else {
                knownGroups[numGroups] = {
                  id: res.data[0].id,
                  name: res.data[0].profile.name,
                  description: res.data[0].profile.description
                }                
              }
            })
          } catch(e) {
            //Set error state & add errors to the files
            this.isFailed = true
            this.fileContent += "Target : " + externalCompany + "\nError : "
            if(e == -1) {
              this.fileContent += "No external company with this name was found\n\n"
            }
            else if(e.response.data.errorCauses.length == 0){
              this.fileContent += e.response.data.errorSummary + "\n"
            }
            else if(e.response.data.errorCauses === undefined){
              this.fileContent += this.$t('error.unknown') + "\n"
            } else {
              for(let i in e.response.data.errorCauses){
                this.fileContent += e.response.data.errorCauses[i].errorSummary + "\n"
              }
            }
            continue
          }
          
          //set group the one just retrieved
          group = knownGroups[numGroups]
          increment = true
        }
        if(increment){
          numGroups++
        }
        //call for user creation
        const url = encodeURI(process.env.VUE_APP_BACKEND_URI + "api/v1/users?activate=true")
        const headers = {
            "Accept": "application/json",
            "Content-Type": "application/json"
          }
        //add calculated data
        let data = {
          "profile": {
            "login": this.csvContent[user].username,
            "firstName": this.csvContent[user].firstname,
            "lastName": this.csvContent[user].lastname,
            "email": this.csvContent[user].username,
            "displayName": this.csvContent[user].firstname + " " + this.csvContent[user].lastname,
            "streetAddress": "OUT OF MH",
            "countryCode": this.csvContent[user].countrycode,
            "locale": this.languageToLocale(this.csvContent[user].language),
            "department": "OUT OF MH",
            "managerId": group.description,
            "initials": "#PRT",
            "externalCompany": externalCompany,
            "startDate": this.csvContent[user].startdate,
            "terminationDate": this.csvContent[user].terminationdate,
            "division": "OUT OF MH",
          },/*
          "credentials":{
            "password" : { "value": "Fromage123!"}
          },*/
          "groupIds": [
            group.id
          ]
        }
        try{
          await axios.post(url, data ,{headers})
        } catch(e) {
          //Set error state & add errors to the files
          this.isFailed = true
          this.fileContent += "Target : " + this.csvContent[user].username + "\nError : "
          if(e.response.data.errorCauses.length == 0){
            this.fileContent += e.response.data.errorSummary + "\n"
          }
          else if(e.response.data.errorCauses === undefined){
            this.fileContent += this.$t('error.unknown') + "\n"
          } else {
            for(let i in e.response.data.errorCauses){
              this.fileContent += e.response.data.errorCauses[i].errorSummary + "\n"
            }
          }
          continue
        }
      }
      
    },

    /**
     * Function to trigger in order to update users
     */
    async updateUsers(){
      for(let user in this.csvContent){
        //if a value is empty => delete from the object in order to not update it with empty value
        Object.keys(this.csvContent[user]).forEach((k) => this.csvContent[user][k] == "" && delete this.csvContent[user][k]);
        //user Id declaration and get userID with API call
        let userId
        let url = encodeURI(process.env.VUE_APP_BACKEND_URI + "api/v1/users/" + this.csvContent[user].username)
        const headers = {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
        try{
          await axios.get(url ,{headers}).then(res => {
            userId = res.data.id
          })
        } catch(e) {
          //Set error state & add errors to the files
          this.isFailed = true
          this.fileContent += "Target : " + this.csvContent[user].username + "\nError : "
          if(e.response.data.errorCauses.length == 0){
            this.fileContent += e.response.data.errorSummary + "\n"
          }
          else if(e.response.data.errorCauses === undefined){
            this.fileContent += this.$t('error.unknown') + "\n"
          } else {
            for(let i in e.response.data.errorCauses){
              this.fileContent += e.response.data.errorCauses[i].errorSummary + "\n"
            }
          }
          continue
        }

        //call API for user update
        url = encodeURI(process.env.VUE_APP_BACKEND_URI + "api/v1/users/" + userId)
        let data = {
          "profile": {
            "terminationDate": this.csvContent[user].terminationdate,
            "firstName": this.csvContent[user].firstname,
            "lastName": this.csvContent[user].lastname,
            "displayName": this.csvContent[user].firstname + " " + this.csvContent[user].lastname,
            "locale": this.languageToLocale(this.csvContent[user].language)
          }
        }
        try{
          await axios.post(url, data ,{headers})
        } catch(e) {
          //Set error state & add errors to the files
          this.isFailed = true
          this.fileContent += "Target : " + this.csvContent[user].username + "\nError : "
          if(e.response.data.errorCauses == []){
            this.fileContent += e.response.data.errorSummary + "\n"
          }
          else if(e.response.data.errorCauses === undefined){
            this.fileContent += this.$t('error.unknown') + "\n"
          } else {
            for(let i in e.response.data.errorCauses){
              this.fileContent += e.response.data.errorCauses[i].errorSummary + "\n"
            }
          }
        }
      }
      
    },

    /**
     * function to trigger in order to deactivate or reactivate users
     */
    async deactivateOrReactivateUsers(action){
      for(let user in this.csvContent){
        //User Id declaration and get userID with API call
        let userId

        let url = encodeURI(process.env.VUE_APP_BACKEND_URI + "api/v1/users/" + this.csvContent[user].username)
        const headers = {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
        try{
          await axios.get(url ,{headers}).then(res => {
            userId = res.data.id
          })
        } catch(e) {
          //Set error state & add errors to the files
          this.isFailed = true
          this.fileContent += "Target : " + this.csvContent[user].username + "\nError : "
          if(e.response.data.errorCauses.length == 0){
            this.fileContent += e.response.data.errorSummary + "\n"
          }
          else if(e.response.data.errorCauses === undefined){
            this.fileContent += this.$t('error.unknown') + "\n"
          } else {
            for(let i in e.response.data.errorCauses){
              this.fileContent += e.response.data.errorCauses[i].errorSummary + "\n"
            }
          }
          continue
        }
        
        //call API for user deactivation or reactivation depending on the action in parameter
        url = encodeURI(process.env.VUE_APP_BACKEND_URI + "api/v1/users/" + userId + "/lifecycle/" + action)
        try{
          await axios.post(url, {}, {headers})
        } catch(e){
          //Set error state & add errors to the files
          this.isFailed = true
          this.fileContent += "Target : " + this.csvContent[user].username + "\nError : "
          if(e.response.data.errorCauses.length == 0){
            this.fileContent += e.response.data.errorSummary + "\n"
          }
          else if(e.response.data.errorCauses === undefined){
            this.fileContent += this.$t('error.unknown') + "\n"
          } else {
            for(let i in e.response.data.errorCauses){
              this.fileContent += e.response.data.errorCauses[i].errorSummary + "\n"
            }
          }
          continue
        }

        //if activate then modify terminisation date as well
        if(action=="activate"){
          //call API for user update termisation date
          url = encodeURI(process.env.VUE_APP_BACKEND_URI + "api/v1/users/" + userId)
          let data = {
            "profile": {
              "terminationDate": this.csvContent[user].terminationdate
            }
          }
          try{
            await axios.post(url, data ,{headers,retry: 10, retryDelay: 3000})
          } catch(e) {
            //Set error state & add errors to the files
            this.isFailed = true
            this.fileContent += "Target : " + this.csvContent[user].username + "\nError : "
            if(e.response.data.errorCauses == []){
              this.fileContent += e.response.data.errorSummary + "\n"
            }
            else if(e.response.data.errorCauses === undefined){
              this.fileContent += this.$t('error.unknown') + "\n"
            } else {
              for(let i in e.response.data.errorCauses){
                this.fileContent += e.response.data.errorCauses[i].errorSummary + "\n"
              }
            }
          }
        }
        
      }
    },

    isCSV_Valid (input, userEmail, role, definedAction) {
      let error_message = []
      //Check the action
      if(definedAction != "A" && definedAction != "U" && definedAction != "D" && definedAction != "R"){
          error_message.push(this.$t('validate_data.error_action'))
      }
      //if add or Update
      if(definedAction == "A" || definedAction == "U" || definedAction == "R"){
        let requiredFields

        //Declaration of required field base on the action
        if(definedAction == "A") requiredFields = ["firstname", "lastname", "username", "countrycode", "startdate", "terminationdate","language"]
        if(definedAction == "U") requiredFields = ["firstname", "lastname","username", "terminationdate","language"]
        if(definedAction == "R") requiredFields = ["username", "terminationdate"]
         
        //If the user is not a manager, he needs to add the external company
        if(definedAction == "A" && role != "Manager"){
          requiredFields.push("externalcompany")
        }

        //Get list of unnecessary fields given in CSV and add to error message
        const nonNecessaryFields = this.hasNonNecessaryHeaders(requiredFields, input[0])
        if(nonNecessaryFields != ""){
          error_message.push(nonNecessaryFields + "\n")
        }
        let missingFields = []
        //Create list of missing fields based on the required ones
        for(let field in requiredFields){
          if(Object.prototype.hasOwnProperty.call(input[0], requiredFields[field]) != true){
            missingFields.push(requiredFields[field])
          }
        }
        //if not empty, for each missing fields, add to error return text the missing one
        if(missingFields.length != 0){
          let message = this.$t('validate_data.error_missing_headers')
          for(let missing in missingFields){
            message += missingFields[missing] + " "
          }
          error_message.push(message + "\n")
        }
        //Validation of every input in the CSV based don the attributed rules
        for(let user in input){
          let validation_array = []
          if (!missingFields.includes("username") && requiredFields.includes("username"))validation_array.push(this.validateUsername(input[user].username, userEmail))
          if (!missingFields.includes("firstname") && requiredFields.includes("firstname"))validation_array.push(this.validateName(input[user].firstname,"firstname"))
          if (!missingFields.includes("lastname") && requiredFields.includes("lastname"))validation_array.push(this.validateName(input[user].lastname,"lastname"))
          if (!missingFields.includes("startdate") && requiredFields.includes("startdate")) validation_array.push(this.validateStartDate(input[user].startdate))
          if (!missingFields.includes("terminationdate") && requiredFields.includes("terminationdate"))validation_array.push(this.validateTerminationDate(input[user].terminationdate))
          if (!missingFields.includes("countrycode") && requiredFields.includes("countrycode"))validation_array.push(this.validateCountryCode(input[user].countrycode))
          if (!missingFields.includes("language") && requiredFields.includes("language"))validation_array.push(this.validateLanguage(input[user].language))
          //if errors are present, add them to the error message
          for(let validation in validation_array) {
            if(validation_array[validation] != true){
              let line = new Number(user) + 2
              error_message.push(this.$t('validate_data.line') + line + ": " + validation_array[validation] + "\n")
            }
          }
        }
        //If error => return the list of errors, else => return true
        if(error_message != ""){
          return error_message
        }else {
          return true
        }
      //If the action is departure or reactivation
      } else if (definedAction == "D" || definedAction == "R") {
        //Get list of unnecessary fields and fill error message if present
        const nonNecessaryFields = this.hasNonNecessaryHeaders(["username"], input[0])
        if(nonNecessaryFields != ""){
          error_message.push(nonNecessaryFields+ "\n")
        }
        //Check if username is present, return error to error_message if not
        if(Object.prototype.hasOwnProperty.call(input[0], "username") != true){
          error_message.push(this.$t('validate_data.error_username_missing'))
        }
        //Validation of every input in the CSV based don the attributed rules
        for(let user in input){
          let validation
          validation = this.validateUsername(input[user].username, userEmail)
          if(validation != true){
            let line = new Number(user) + 2
            error_message.push(this.$t('validate_data.line') + line + ": " + validation + "\n")
          }
        }
        //If error => return the list of errors, else => return true
        if(error_message != ""){
          return error_message
        }else {
          return true
        }
      } else {
        return error_message
      }
    },

    /**
     * Validation of names (firstNames, lastNames)
     */
    validateName(name,type){
      if (typeof name === "undefined") name = ""
      if(name.length < 1 || name.length > 50){
        if (name == "") name = "undefined"
        
        switch (type) {
          case 'firstname':
            return this.$t('validate_data.error_firstname') + name
          case 'lastname':
            return this.$t('validate_data.error_lastname') + name
          default:
            return this.$t('validate_data.error_name') + name
        }
        
      } else {
        return true
      }
    },

    /**
     * Validate the username which is an email
     */
    validateUsername(username, userEmail){
        const regex = /^\s*[\w\-+_]+(\.[\w\-+_]+)*@[\w\-+_]+\.[\w\-+_]+(\.[\w\-+_]+)*\s*$/gm
        if (typeof username === "undefined") username = ""
        if(username.length < 5 || username.length > 100 || !(regex.test(username))){
          if (username == "") username = "undefined"
          return this.$t('validate_data.error_username') + username
        } else if(username == userEmail) {
          return this.$t('validate_data.error_same_username')
        }
        else {
          return true
        }
    },

    /**
     * Validate the start date which is a date
     */
    validateStartDate(date){
      const regex = /(?<=\D|^)(?<month>1[0-2]|0[1-9])\/(?<day>0[1-9]|[12][0-9]|30|31|)\/(?<year>\d{4})(?=\D|$)/gm
      new Date(date)
      if(!(regex.test(date))){
        if (date == "") date = "undefined"
        return this.$t('validate_data.error_startDate') + date
      } else {
        return true
      }
    },

    /**
     * Validate the termination date which is a date and should be > to the start date
     */
    validateTerminationDate(date){
      const regex = /(?<=\D|^)(?<month>1[0-2]|0[1-9])\/(?<day>0[1-9]|[12][0-9]|30|31|)\/(?<year>\d{4})(?=\D|$)/gm
      const terminationDate = new Date(date)
      if(!(regex.test(date)) || terminationDate < Date.now()){
        if (date == "") date = "undefined"
        return this.$t('validate_data.error_terminationDate') + date
      }else{
        return true
      }
    },

    /**
     * Validate the country code which is a two char string following the related ISO Country name code rule (lib used)
     */
    validateCountryCode(countryCode){
      if (typeof countryCode === "undefined") countryCode = ""
      if (getName(countryCode) == undefined){
        if (countryCode == "") countryCode = "undefined"
        return this.$t('validate_data.error_countryCode') + countryCode
      } else {
        return true
      }
    },
    validateLanguage(language){
      if (typeof language === "undefined") language = ""
      // eslint-disable-next-line
      if ( language == "EN") language = "US"
      if (getName(language) == undefined){
        if (language == "") language = "undefined"
        return this.$t('validate_data.error_language') + language
      } else {
        return true
      }
    }
    ,
    languageToLocale(language){
     if (language == "FR" ) return "fr_FR"
        return "en_US"
    }
    ,

    /**
     * Get the list of unnecessary fields based on the required ones
     */
    hasNonNecessaryHeaders(requiredFields, allFields){
      let allFields_array = (Object.keys(allFields))
      if(requiredFields.length == allFields_array.length){
        return ""
      } else{
        let message = ""
        let once = false
        //this.$t('validate_data.error_too_many_headers')
        for(let field in allFields_array){
          if(!requiredFields.includes(allFields_array[field])) { 
            if(!once) message+= this.$t('validate_data.error_too_many_headers')
            once = true
            message += allFields_array[field] + " " 
          }
        }
        return message
      }
    }
  }
}
</script>

<style scoped lang="scss">
.importCSV{
  margin-left: 5%;
  margin-right: 5%;
}

.custom-file-button {
	input[type="file"] {
		margin-left: -2px !important;

		&::-webkit-file-upload-button {
			display: none;
		}
		&::file-selector-button {
			display: none;
		}
	}

	&:hover {
		label {
			background-color: #dde0e3;
			cursor: pointer;
		}
	}
}

.form{
  text-align: left;
}
</style>