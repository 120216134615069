<template>
  <div class="users">
    <HeadTitle :mainTitle="$t('usersView.title')"/>
    <div class="form">
      <div class="row"><!--
        <div class="col-auto">
          <label for="user" class="col-form-label">{{ $t('usersView.label_searching_user') }}</label>
        </div>-->
        <div class="col-4">
          <input type="text" v-model="user" id="user" class="form-control" v-on:keyup.enter="searchUser" v-bind:class="{'is-invalid' : errorSearch}">
        </div>
        <div class="col-auto">
          <button @click="searchUser" class="btn btn-primary">{{ $t('usersView.btn_search') }}</button>
        </div>
        <div class="spinner-border text-primary col-auto" role="status" v-show="loading">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div class="col-auto me-auto">
          <span style="color:red" v-if="errorSearch">{{ $t('error.unknown') }}</span>
        </div>
      </div>
    </div>
    <br>
    <div class="userList">
      <TableLite
        :is-slot-mode="true"
        :is-loading="table.isLoading"
        :columns="table.columns"
        :rows="table.rows"  
        :total="table.totalRecordCount"
        :sortable="table.sortable"
        @do-search="doSearch">
        <template v-slot:name="data">
        {{ data.value.name }}
      </template>
      </TableLite>
    </div>
    <div class="row align-items-center foot">
      <div class="col-12">
        <button @click="nextUser" v-show="hasNext" class="btn btn-primary">{{ $t('usersView.next') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import HeadTitle from '../../components/HeadTitle.vue'
import axios from 'axios'
import { reactive, computed } from 'vue'
import TableLite from "../../components/TableLite.vue";
export default {
  name: 'UsersView',
  components: {
    HeadTitle,
    TableLite
  },
  data(){
    //Table of the list of users
    const table = reactive({
      isLoading: false,
      columns: [
        {
          label: this.$t('usersView.login'),
          field: "login",
          width: "30%",
          sortable: true,
          isKey: true,
        },
        {
          label: this.$t('usersView.firstName'),
          field: "firstName",
          width: "15%"
        },
        {
          label: this.$t('usersView.lastName'),
          field: "lastName",
          width: "15%"
        },
        {
          label: this.$t('usersView.startDate'),
          field: "startDate",
          width: "15%"
        },
        {
          label: this.$t('usersView.terminationDate'),
          field: "terminationDate",
          width: "15%"
        },
        {
          label: this.$t('usersView.externalCompany'),
          field: "externalCompany",
          width: "25%"
        },
        {
          label: this.$t('usersView.language'),
          field: "language",
          width: "10%"
        },
        {
          label: this.$t('usersView.status'),
          field: "status",
          width: "10%"
        }
      ],
      rows: [],
      totalRecordCount: computed(() => {
        return table.rows.length;
      }),
      sortable: {
        order: "apiLastUpdate",
        sort: "desc",
      },
    });

    /**
     * Search Event
     */
    const doSearch = async (offset, limit, order, sort) => {
      //Refresh related states
      this.errorSearch = false
      this.loading = true;
      this.table.isLoading = true;
      let current_user = this.user
      try {
        //get first name & last name
        const firstName = current_user.split(" ")[0]
        const lastName = current_user.split(" ")[1]
        let url, userEncode
        if(current_user == ""){
          url = process.env.VUE_APP_BACKEND_URI + 'api/v1/users?limit=10&search=profile.initials+eq+"%23PRT"+and+(status+eq+"ACTIVE"+or+status+eq+"STAGED"+or+status+eq+"RECOVERY"+or+status+eq+"DEPROVISIONED"+or+status+eq+"SUSPENDED"+or+status+eq+"PROVISIONED"+or+status+eq+"LOCKED_OUT"+or+status+eq+"PASSWORD_EXPIRED")' + '&sortBy=' + order + '&sortOrder=' + sort
        //If white space => search if (first name + last name) or first name or last name or username or email or or external company start with given field
        } else if(current_user.includes(" ") && lastName!="") {
          userEncode = current_user.replace(" ", "+")
          userEncode = encodeURIComponent(userEncode)
          url = process.env.VUE_APP_BACKEND_URI + 'api/v1/users?limit=10&search=profile.initials+eq+"%23PRT"+and+((profile.firstName+sw+"' + firstName + '"+and+(profile.lastName+sw+"' + lastName + '")))+or+profile.firstName+sw+"' + userEncode + '"+or+profile.lastName+sw+"' + userEncode + '"+or+profile.login+sw+"' + userEncode + '"+or+profile.email+sw+"' + userEncode + '"+or+profile.externalCompany+sw+"' + userEncode + '"&sortBy=' + order + '&sortOrder=' + sort
        //If no white space => search if first name or last name or username or email or external company start with given field
        } else {
          userEncode = current_user.replace(" ", "")
          userEncode =  encodeURIComponent(userEncode)
          url = process.env.VUE_APP_BACKEND_URI + 'api/v1/users?limit=10&search=profile.initials+eq+"%23PRT"+and+(profile.firstName+sw+"' + userEncode + '"+or+profile.lastName+sw+"' + userEncode + '"+or+profile.login+sw+"' + userEncode + '"+or+profile.email+sw+"' + userEncode + '"+or+profile.externalCompany+sw+"' + userEncode + '")&sortBy=' + order + '&sortOrder=' + sort
        }
        if(this.hasNext && offset != 0){
          url += '&after=' + this.oldAfter
        }
        const headers = {
          "Accept": "application/json",
          "Content-Type": "application/json",
        }
        //Get the list of users
        await axios.get(url,{headers}).then(res => {
          if(res.data.length == 0 && this.hasNext){ 
            this.hasNext = false
            this.oldAfter = "0"
            return
          } else if(res.data.length == 0 && !this.hasNext){
            this.table.rows = []
          }
          for(let userFound in res.data){
            let user_found = {
              id: res.data[userFound].id,
              login: res.data[userFound].profile.login,
              firstName: res.data[userFound].profile.firstName,
              lastName: res.data[userFound].profile.lastName,
              startDate: res.data[userFound].profile.startDate,
              terminationDate: res.data[userFound].profile.terminationDate,
              externalCompany: res.data[userFound].profile.externalCompany,
              status: res.data[userFound].status,
              language: res.data[userFound].profile.locale=="fr_FR" ?"FR":"EN"
            }
            let index = parseInt(userFound) + parseInt(this.tableSize)
            this.table.rows[index] = user_found
          }
          try{
            this.oldAfter = res.headers.link.split(", ")[1].split('users?after=')[1].split('&limit=')[0]
            this.hasNext = true
          } catch (e) {
            this.oldAfter = "0"
            this.hasNext = false
          }
        })
        //Set success state
        this.loading = false
        this.table.isLoading = false;
      } catch (e) {
        //Set error state
        this.errorSearch = true
        this.loading = false
        this.table.isLoading = false
      }
      this.table.sortable.order = order
      this.table.sortable.sort = sort
    };

    //Trigger the search event with defined parameters
    const searchUser = async () => {
      this.tableSize = 0
      this.table.rows = []
      doSearch(0, 10, "apiLastUpdate", "desc")
    }

    //prompt the following users
    const nextUser = async () => {
      if(this.oldAfter == "0"){
        this.table.rows = []
        this.tableSize = 0
      }
      this.tableSize += 10
      doSearch(this.oldAfter, this.tableSize, "apiLastUpdate", "desc")
    }


    return {
      //variable contains 0 if no more users can be prompted. Otherwise contains the after value from the header link
      oldAfter: "0",
      //boolean value determining if their is more users or not
      hasNext: false,
      //user in the search field
      user: "",
      //state used for the modal
      loading: false,
      //table variable
      table,
      //Scalable size of the table
      tableSize: 0,
      //To Return error
      errorSearch: false,
      //methods
      doSearch,
      searchUser,
      nextUser
    }
  },

  //Trigger do search when the view is mounted
  mounted(){
    this.doSearch(0, 10, "apiLastUpdate", "desc")
  }
}
</script>

<style scoped lang="scss">
.users{
  margin-left: 5%;
  margin-right: 5%;
}

.foot{
  margin-bottom: 10px;
}

.form{
  text-align: left;
}
</style>